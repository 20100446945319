// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-album-tsx": () => import("./../src/templates/album.tsx" /* webpackChunkName: "component---src-templates-album-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-delete-tsx": () => import("./../src/pages/about.delete.tsx" /* webpackChunkName: "component---src-pages-about-delete-tsx" */),
  "component---src-pages-index-delete-tsx": () => import("./../src/pages/index.delete.tsx" /* webpackChunkName: "component---src-pages-index-delete-tsx" */)
}

